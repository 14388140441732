import React, {useState, useEffect} from "react";
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import axios from "axios";
import { useNavigate } from 'react-router-dom';

function HandleEvent({theUser, theEvent, theDate}) {

    const [eventName, setEventName] = useState("");
    const [eventDescr, setEventDescr] = useState("");
    const [eventStart, setEventStart] = useState("");
    const [eventEnd, setEventEnd] = useState("");
    
    const [eventStatus, setEventStatus] = useState("");
    const [currStatus, setCurrStatus] = useState(""); //Maintain current status so that I can render off of this, without it changing based on user selection

    const [formErrors, setFormErrors] = useState({}); //Monitor the errors within the Form

    const eventType = theEvent.event_id ? "E" : "N"; //E = Edit N = New

    const navigate = useNavigate(); //Required to route back to calendar page once Event has been handled

    //Get the information for the Event and set the State
    useEffect(() => {

      //Only fetch data if this is an Edit of an existing Event
      if(eventType === "E") {
        
        const fetchEvent = async () => {

          try {

              const response = await axios.get(`https://api.leost.co.nz/event/${theEvent.event_id}`, {
                headers: {
                    Authorization: `Bearer ${theUser.idJWT}`
                }
              });
              
              const resEvent = response.data[0];
              
              setEventName(resEvent.event_name);
              setEventDescr(resEvent.event_description);
              setEventStart(resEvent.event_start_date);
              setEventEnd(resEvent.event_end_date); 
              setEventStatus(resEvent.event_status);
              setCurrStatus(resEvent.event_status);

          } catch (error) {
              handleClear();
              console.error('Error fetching Event', error);
          }

        };

        fetchEvent();
        
      }
      //Where the user has come from a "CalendarFullDay" then set the start date
      else if(theDate) {
        //A better way to do this would be to have a properly formatted date BUT this is workable for now
        const formDate = theDate.event_date_id.toString();
        setEventStart(`${formDate.substring(0, 4)}-${formDate.substring(4, 6)}-${formDate.substring(6, 8)}`);
      }

    }, []);

    //Check that the form is ok
    const validateForm = () => {

      const newErrors = {};

      const startDate = new Date(eventStart);
      const endDate = new Date(eventEnd);

      if(endDate < startDate) {
        newErrors.dateError = "Start Date is after End Date. Please Resolve.";
      };

      setFormErrors(newErrors);

      return Object.keys(newErrors).length === 0; //Object.keys returns an array of all keys in the Object, then can find the length

    };

    //Save the Event Entry
    const handleSave = async (e) => {

        e.preventDefault();

        if (validateForm()) {

          //Adding a new Event
          if(eventType === "N")
          {

            const newEvent = {
                event_name: eventName,
                event_description: eventDescr,
                event_start_date_id: parseInt(eventStart.replace(/-/g, ''), 10),
                event_end_date_id: parseInt(eventEnd.replace(/-/g, ''), 10),
                event_user_id: theUser.userID,
                event_location_id: 1,
                event_type_id: 1,
                event_status: "P"
            };

            //Write to the Database
            try {

              const response = await axios.post('https://api.leost.co.nz/event', newEvent, {
                headers: {
                    Authorization: `Bearer ${theUser.idJWT}`
                }
              });

              console.log(response);
              navigate("/calendar"); //Navigate back to the calendar on successful Save
              
            } catch (error) {
              console.error('Error saving Event', error);
            };

          }

          //Editing an existing Event
          else 
          {

            const editEvent = {
              event_id: theEvent.event_id,
              event_name: eventName,
              event_description: eventDescr,
              event_start_date_id: parseInt(eventStart.replace(/-/g, ''), 10),
              event_end_date_id: parseInt(eventEnd.replace(/-/g, ''), 10),
              event_status: eventStatus
            };

            //Write to the Database
            try {
              const response = await axios.put('https://api.leost.co.nz/event', editEvent, {
                headers: {
                    Authorization: `Bearer ${theUser.idJWT}`
                }
              });

              console.log(response);
              navigate("/calendar"); //Navigate back to the calendar on successful Save
              
            } catch (error) {
              console.error('Error saving Event', error);
            };

          };

        };

    };

    //Clear the Event Entry
    const handleClear = (e) => {
        setEventName("");
        setEventDescr("");
        setEventStart("");
        setEventEnd("");
        setEventStatus("");
    };

    return (

        <Container className="mt-4">

        <Row>
          <Col md={12} className="mt-4 mb-4">
            <h3>{eventType === "E" ? `Your Event: ${eventName}` : "New Event"}</h3>
          </Col>
        </Row>

        <Form onSubmit={handleSave}>

          <Form.Group controlId="eventName" className="mb-3">
            <Form.Label>Event Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Event Name"
              value={eventName}
              onChange={(e) => setEventName(e.target.value)}
              maxLength={15}
              required
            />
          </Form.Group>
  
          <Form.Group controlId="eventStartDate" className="mb-3">
            <Form.Label>Event Start Date</Form.Label>
            <Form.Control
              type="date"
              value={eventStart}
              onChange={(e) => setEventStart(e.target.value)}
              required
              isInvalid={!!formErrors.dateError}
              style={{ textAlign: 'left' }}
            />
            <Form.Control.Feedback type="invalid">
              {formErrors.dateError}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="eventEndDate" className="mb-3">
            <Form.Label>Event End Date</Form.Label>
            <Form.Control
              type="date"
              value={eventEnd}
              onChange={(e) => setEventEnd(e.target.value)}
              required
              style={{ textAlign: 'left' }}
            />
          </Form.Group>
  
          <Form.Group controlId="eventDescription" className="mb-3">
            <Form.Label>Event Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={2}
              placeholder="Enter Event Description"
              value={eventDescr}
              onChange={(e) => setEventDescr(e.target.value)}
              maxLength={50}
            />
          </Form.Group>

          {
            eventType === "E" && (
             <Form.Group controlId="eventStatus" className="mb-3">
                <Form.Label>Event Status</Form.Label>
                <Form.Control as="select" value={eventStatus} onChange={(e) => setEventStatus(e.target.value)}>
                  {currStatus === "P" && <option value="P">Pending</option>}
                  {(currStatus === "B" || (currStatus === "P" && theUser.userType.toUpperCase() === "ADMIN")) && <option value="B">Booked</option>}
                  <option value="C">Cancel</option>
                </Form.Control>
              </Form.Group>
            )
          }

          <Row className="mt-5">

            <Col md={2} sm={4} xs={6}>
                <Button variant="primary" type="submit">
                  {eventType === "E" ? "Update Event" : "Create Event"}
                </Button>
            </Col>

            <Col md={2} sm={4} xs={6}>
                <Button variant="secondary" type="button" onClick={handleClear}>
                   Clear Form
                </Button>
            </Col>

          </Row>

        </Form>

      </Container>

    );

};

export default HandleEvent;